var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.preference.allow_trust == 1 ? _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('h5', [_vm._v(_vm._s(this.product.name))])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4 mt-3"
  }, [_c('p', [_vm._v(" 1. " + _vm._s(_vm.$t("preference.activate")) + " "), _c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.status,
      expression: "form.status"
    }],
    attrs: {
      "type": "checkbox",
      "value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.status) ? _vm._i(_vm.form.status, "1") > -1 : _vm.form.status
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.form.status,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "status", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "status", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "status", $$c);
        }
      }
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('div', {
    staticClass: "col-md-4 mt-3"
  }, [_c('p', [_vm._v(" 2. " + _vm._s(_vm.$t("preference.allow-public")) + " "), _c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.allow_public,
      expression: "form.allow_public"
    }],
    attrs: {
      "type": "checkbox",
      "value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.allow_public) ? _vm._i(_vm.form.allow_public, "1") > -1 : _vm.form.allow_public
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.form.allow_public,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "allow_public", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "allow_public", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "allow_public", $$c);
        }
      }
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('div', {
    staticClass: "col-md-4 mt-3"
  }, [_c('p', [_vm._v(" 3. " + _vm._s(_vm.$t("preference.allow-payments")) + " "), _c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.allow_payment,
      expression: "form.allow_payment"
    }],
    attrs: {
      "type": "checkbox",
      "value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.allow_payment) ? _vm._i(_vm.form.allow_payment, "1") > -1 : _vm.form.allow_payment
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.form.allow_payment,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "allow_payment", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "allow_payment", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "allow_payment", $$c);
        }
      }
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])])]), _c('hr'), _vm.preference.allow_trust == 1 ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-3 mt-3"
  }, [_c('ul', [_c('li', [_c('p', [_vm._v(_vm._s(_vm.$t("preference.total-receivers")))])])])]), _c('div', {
    staticClass: "col-md-3 mt-3"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.receiver_count,
      expression: "form.receiver_count"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number",
      "name": "harta tetap"
    },
    domProps: {
      "value": _vm.form.receiver_count
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "receiver_count", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-3 mt-3"
  }, [_c('ul', [_c('li', [_c('p', [_vm._v(_vm._s(_vm.$t("preference.total-caretakers")))])])])]), _c('div', {
    staticClass: "col-md-3 mt-3"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.caretaker_count,
      expression: "form.caretaker_count"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number",
      "name": "harta tetap"
    },
    domProps: {
      "value": _vm.form.caretaker_count
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "caretaker_count", $event.target.value);
      }
    }
  })])]) : _vm._e(), _vm.preference.allow_trust == 1 ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-3 mt-3"
  }, [_c('ul', [_c('li', [this.product.name == 'Deklarasi Amanah Korporat' ? _c('p', [_vm._v(_vm._s(_vm.$t("preference.total-property")))]) : _c('p', [_vm._v(_vm._s(_vm.$t("preference.total-mov-prop")))])])])]), _c('div', {
    staticClass: "col-md-3 mt-3"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.moveable_property_count,
      expression: "form.moveable_property_count"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number",
      "name": "harta tetap"
    },
    domProps: {
      "value": _vm.form.moveable_property_count
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "moveable_property_count", $event.target.value);
      }
    }
  })]), this.product.name != 'Deklarasi Amanah Korporat' ? _c('div', {
    staticClass: "col-md-3 mt-3"
  }, [_c('ul', [_c('li', [_c('p', [_vm._v(_vm._s(_vm.$t("preference.total-immov-prop")))])])])]) : _vm._e(), this.product.name != 'Deklarasi Amanah Korporat' ? _c('div', {
    staticClass: "col-md-3 mt-3"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.immoveable_property_count,
      expression: "form.immoveable_property_count"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number",
      "name": "harta tetap"
    },
    domProps: {
      "value": _vm.form.immoveable_property_count
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "immoveable_property_count", $event.target.value);
      }
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "col-md-3 mt-3"
  }, [_c('ul', [_c('li', [_c('p', [_vm._v(_vm._s(_vm.$t("preference.trust-property")))])])])]), _c('div', {
    staticClass: "col-md-3 mt-3"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.fixed_saham_type,
      expression: "form.fixed_saham_type"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "harta tetap"
    },
    domProps: {
      "value": _vm.form.fixed_saham_type
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "fixed_saham_type", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-3 mt-3"
  }, [_c('ul', [_c('li', [_c('p', [_vm._v(_vm._s(_vm.$t("preference.stock-acc-type")))])])])]), _c('div', {
    staticClass: "col-md-3 mt-3"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.corporate_saham_account_type,
      expression: "form.corporate_saham_account_type"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "corporate_saham_account_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "No. Akaun"
    }
  }, [_vm._v(_vm._s(_vm.$t("acc-no")))]), _c('option', {
    attrs: {
      "value": "No. Perjanjian"
    }
  }, [_vm._v(_vm._s(_vm.$t("agreement-no")))]), _c('option', {
    attrs: {
      "value": "No. Polisi"
    }
  }, [_vm._v(_vm._s(_vm.$t("policy-no")))]), _c('option', {
    attrs: {
      "value": "No. Pendaftaran Syarikat"
    }
  }, [_vm._v(_vm._s(_vm.$t("co-regno")))])])])]) : _vm._e(), _c('hr'), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.submitOrganizationProduct
    }
  }, [_c('i', {
    staticClass: "mdi mdi-file-document"
  }), _vm._v(_vm._s(_vm.$t("save")) + " ")])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }